export const nav = [

    {
        name: 'Set1',
        id: 1
    },
    {
        name: 'Set2',
        id: 2
    },
    {
        name: 'Set3',
        id: 3
    },
    {
        name: 'Set4',
        id: 4
    },
    {
        name: 'Set5',
        id: 5
    }

]