import './Card.css'
const Card = ({cardsize,id,email,username,name,setName}) =>
{

    
    return (
        <div className='tc bg-light-green dib br3 pa3 ma2 grow bw2 shadow-5'>
        <img src ={`https://robohash.org/${id}?set=${setName}&size=${cardsize}x${cardsize}`} alt={username}/>
            <div>
                <h2>{name}</h2>
                <h3>{username}</h3>
                <p>{email}</p>
            </div>
        </div>
    );
}

export default Card;